$(function () {
  //画像スライダー
  $(".js-image-slider").slick({
    dots: true,
    arrows: false,
    fade: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 3000,
  });

  //ピックアップスライダー
  $(".js-pick-up-slider").slick({
    dots: true,
    arrows: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  //ニュースタブ
  $(".js-news-tab-button").on("click", function () {
    $(".js-news-tab-button").removeClass("news-category__button--selected");
    $(".js-news-archive").removeClass("news-archive--active");
    $(this).addClass("news-category__button--selected");
    let newsTabCategory = $(this).attr("data-news-tab-category");
    $(".js-news-archive[data-news-tab-category='" + newsTabCategory + "']").addClass("news-archive--active");
  })
})